.ui.attached.segment,
.ui.stackable.grid {
	background-color: #212529;
}

.temp-white {
	background-color: #fff !important;
}

/* buttons */
.button {
	border-radius: 25px !important;
}

/* tables */
.ui.table {
	box-shadow: none !important;
	border-radius: 25px !important;
}

.ui.table thead tr:first-child>th:only-child {
	font-size: 17px;
	border-radius: 25px !important;
	padding: 25px 25px 17px !important;
}

.ui.table thead,
.ui.table tbody,
.ui.table tr {
	border-radius: 25px !important;
}

.ui.table td {
	padding: 10px 25px !important;
	border: 0 !important;
	border-radius: 25px !important;
}

.table.welcome {
	font-size: 28px;
	font-weight: bolder;
	text-align: center;
	color: #fff;
}

.table.category {
	color: #D7D7D7;
	font-size: 16px;
	font-weight: 400;
	padding: 0;
	margin-bottom: 5px;
}

.table.title {
	color: #949696;
	margin-bottom: 5px;
}

.ui.table:not(.unstackable) tr {
	box-shadow: none !important;
}

@media only screen and (max-width: 767px){
	.ui.table:not(.unstackable) td:first-child, .ui.table:not(.unstackable) th:first-child {
		font-weight: 400;
	}
	.ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th {
		padding: 8px 25px !important;
	}
	.ui.table thead tr:first-child>th:only-child {
		padding: 17px 25px 0 !important;
	}
}

.ui.secondary.pointing.menu {
	margin-bottom: 0 !important;
}

@media (min-width: 768px){
	.ui.table tbody tr:last-child>td {
		padding-bottom: 20px !important;
	}
}

@media (min-width: 768px) and (max-width: 1250px){
	table.ui.responsive,
	table.ui.responsive tr {
		display: flex;
		flex-direction: column;
	}
}

/* Arena hero farm / Jooriebot auto */
.Arena .ui.form .fields,
.Jooriebot .ui.form .fields {
	background-color: transparent !important;
	flex-direction: column !important;
}
.Arena .ui.form .fields > .field {
	background-color: transparent !important;
}
.Arena .ui.form .fields label {
	color: #fff !important;
}
.Jooriebot label {
	font-weight: bold !important;
}

/* leaderboards */
.Leaderboards .ui.table thead tr:first-child>th:first-child {
	border-radius: 25px 0 0 25px !important;
	padding-left: 20px !important;
}
.Leaderboards .ui.table thead tr:first-child>th:last-child {
	border-radius: 0 25px 25px 0 !important
}

/* alerts */
.Alerts > .container,
.Callback > .container {
	font-family: Roboto;
    max-width: 100% !important;
    margin: 40px auto 100px auto;
    padding: 30px 40px 30px 40px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
}

@media(min-width: 767px){
	.Alerts > .container,
	.Callback > .container {
		max-width: 600px !important;
	}
}